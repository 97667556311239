<!-- 
	一个'成功案例'的标签
	zjy
	2021.2.19
 -->
<template>
	<div class="tag">
		<span>
			成功<br/>案例
		</span>
	</div>
</template>

<script>
export default{
	name:'successTag'
}
</script>

<style lang="scss">
	@media(min-width:1201px){
		.tag{
			width: 100px;
			height: 100px;
			background-color: #3A86FF;
			color: #fff;
			font-size: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@media(max-width: 1200px){
		.tag{
			width: 50px;
			height: 50px;
			display: flex;
			font-size: 16px;
			justify-content: center;
			align-items: center;
			position: absolute;
			background-color: #3A86FF;
			position: absolute;
			top: 0;
			left: 0;
			span{
				color: #fff;
			}
		}
	}

</style>
