<!-- 
解决方案内容模块之知识产权
zjy
2021.2.19
 -->
<template>
  <div class="solution_main">
    <div class="main_in">
      <!-- div.top_first>div.top_left+div.top_right  div.top_second>div.bottom_left+div.bottom_right			 -->
      <div class="top">
        <Atag></Atag>
        <div class="section">
          <div class="text">
            <div class="textarea">
              <h4>与江宁九龙湖总部园达成战略合作</h4>
              <p>
                成立九龙湖众创企业服务中心，累计服务企业达到十三家，其中为南京用了
                么软件技术服务有限公司，江苏优众智慧云科技有限公司，江苏优智汇精密制造
                有限公司整体打包服务各种配套项目，包含基础项目知识产权申报、AAA信用报
                告、政策解读规划，包括政策申报和资质项目，如：高新技术企业申报、三体系
                、双软等。
              </p>
            </div>
          </div>
          <div class="imgarea">
            <img src="~/assets/images/solution/pic_01.png" alt="找不到资源" />
          </div>
        </div>
        <div class="bottomline"></div>
        <div class="section section_phone">
          <div class="imgarea">
            <img src="~/assets/images/solution/pic_02.png" alt="找不到资源" />
          </div>
          <div class="text">
            <div class="textarea">
              <h4>与南大安高资本研究院达成长期战略合作</h4>
              <p>
                与南大安高资本研究院达成长期战略合作,共同举办高校创业创新大赛。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <!-- <Atag></Atag> -->
        <div class="topimg">
          <img src="~/assets/images/solution/pic_03.png" alt="找不到资源" />
        </div>
        <div class="text">
          <div class="text1">
            <h4>与三宝投资组成企业融资服务部</h4>
            <p>与三宝投资组成企业融资服务部，帮扶助力企业快速成长壮大。</p>
          </div>
        </div>
        <div class="text2">
          <div class="textarea">
            <h4>与瑞友联盟组成合伙制资源合作运营平台</h4>
            <p>
              与瑞友联盟组成合伙制资源合作运营平台，并开设企业运营服务中心。
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="bottom">
				<Atag></Atag>
				<div class="circle"><div class="centercircle"></div></div>
				<div class="topimg">
					<img src="~/assets/images/solution/pic_04.png" alt="找不到资源">
					<img src="~/assets/images/solution/pic_05.png" alt="找不到资源">
				</div>
				<div class="bottomInfo">
					<div class="text">
						<div class="text1">
							<h4>与江宁九龙湖总部园达成战略合作</h4>
							<p>与瑞友联盟，组成合伙制资源合作运营平台，并开设企业运营服务中心。</p>
						</div>
					</div>
					<img src="~/assets/images/solution/pic_06.png" alt="找不到资源">
				</div>
			</div> -->
    </div>
  </div>
</template>

<script>
import Atag from "@/components/content/solution/Atag.vue";
export default {
  name: "SolutionMainContent",
  components: {
    Atag,
  },
};
</script>

<style lang="scss" scoped>
 @media(min-width: 1201px){
   @import '@/components/content/solution/SolutionMain.scss';
 }
 @media(max-width: 1200px){
   @import '@/components/content/solution/SolutionMain_phone.scss';

 }
</style>
