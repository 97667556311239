<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:解决方案页,用于展示
	History: 暂无
-->
<template>
	<div id="solution">
		<div class="solution_in">
			<solution-header></solution-header>
			<solution-main></solution-main>
			<footer-page></footer-page>
		</div>
	</div>
</template>

<script>
import footerPage from '@/components/common/footer/footerPage.vue'
import solutionHeader from '@/components/content/solution/SolutionHeader.vue'
import solutionMain from '@/components/content/solution/SolutionMain.vue'
export default {
	name: 'Solution',
	data(){
		return{
			// picList:[],
		}
	},
	components:{
		footerPage,
		solutionHeader,
		solutionMain
	},
	created() {
		//document.title = this.project_title+'-解决问题'
		this.$store.commit('updateIsActiveIndex',3)
		// //请求轮播图接口
		// let requestParams = {'Status':3,'BelongAreaID':'1'}
		// this.$axios('API/Get_Banner',requestParams).then( res => {
		// 	this.picList = res.data
		// }).catch( err => { console.log(err) })
	},
}
</script>

<style lang="scss" scoped>
@media(min-width: 1201px){
	#solution{
		width: 100%;
		display: flex;
		justify-content: center;
		.solution_in{
			width: 1920px;
		}
	}
}

</style>
