<template>
	<div class="solution_header">
		<!-- <img src="~/assets/images/solution/pic_00.png" alt="找不到资源"> -->
		<keep-alive>
			<ul class="headertab">
				<!-- @click="toActivePage(index)" :class="index == activeIndex ? 'active':'noactive'"-->
				<li v-for="(item,index) in tabList" :key="index" 
				class="noactive"
				>
					<span>
						<!-- {{item.ServiceTypeName}} -->
						{{item.title}}
					</span>
				</li>
			</ul>
		</keep-alive>
	</div>
</template>
<script>
export default {
	name:"SolutionHeader",
	data(){ 
		return {
			tabList:[
				// {id:0,title:"全部"},
				// {id:1,title:"知识产权"},
				// {id:2,title:"高新技术企业"},
				// {id:3,title:"双软认证"},
				// {id:4,title:"资质项目"},
				// {id:5,title:"IOS项目"},
				// {id:6,title:"财会项目"},
				// {id:7,title:"人才项目"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"},
				// {id:8,title:"其他"}
			],
			activeIndex:0
		}
	},
	components:{
		// activeIndex(){
		// 	if(this.$store.state.solutionstore.solution_index == null){
		// 		return 0
		// 	}else{
		// 		return this.$store.state.solutionstore.solution_index
		// 	}
		// }
	},
	created() {
		let requestParams = {'Status':3,'TopNum':0,'IsShowIndex':'0'}
		//请求服务平台接口
		this.$axios('API/Get_ServiceType',requestParams).then((res) => {
			// res.data.forEach( item => this.ServiceTypeGuidList.push(item) )
			
			this.tabList = res.data
		}).catch( err => { console.log(err) })
	},
	methods:{
		toActivePage(index){
			// this.activeIndex = index
			// this.$router.push(this.headertabList[index].path)
			// this.$store.dispatch('solutionstore/solution_index',index)
			// window.sessionStorage.setItem('solution_index',index)
		}
	}
}
</script>

<style lang="scss">
.solution_header{
	width: 100%;
	// height: 500px;
	position: relative;
	img{
		width: 1920px;
		height: 500px;
	}
	.headertab{
		position: absolute;
		bottom: 0;
		width: 1200px;
		// height: 60px;	
		background-color: rgba(0,0,0,.3);
		margin: 0 360px;
		overflow: auto;
		white-space: nowrap;   
		display: flex;
		li{
			margin-left: 50px;
			height: 60px;
			display: flex;
			align-items: center;
			font-size: 16px;
			cursor: pointer;
		}
		.noactive{
			color: #ccc;
			transition: .3s;
			&:hover{
				color: #fff;
			}
		}
		.active{
			color: #FF2A00;
			transition: .3s;
		}
		
	}
}
</style>
